import React, { useState } from 'react'
import { call } from '@advanza/api'
import AutoRow from 'components/misc/AutoRow'
import { Button, Icon, LoadingDots } from '@advanza/ui'

function useExportProvidersByLicence() {
    const [isLoading, setIsLoading] = useState(false)
    const [isDone, setIsDone] = useState(false)

    const callExportProvidersByLicence = async () => {
        setIsLoading(true)
        try {
            return await call('office/misc/export-link-exchange-promo', {})
        } finally {
            setIsLoading(false)
            setIsDone(true)

            setTimeout(function () {
                setIsDone(false)
            }, 3000)
        }
    }

    return {
        isDone,
        isLoading,
        callExportProvidersByLicence,
    }
}

const ExportLinkExchangeWidget = () => {
    const { isLoading, isDone, callExportProvidersByLicence } = useExportProvidersByLicence()

    return (
        <div>
            <AutoRow>
                <Button disabled={isLoading || isDone} onClick={callExportProvidersByLicence}>
                    {isLoading && (
                        <>
                            Exporting <LoadingDots />
                        </>
                    )}
                    {isDone && (
                        <>
                            Done <Icon name="check" />
                        </>
                    )}
                    {!isLoading && !isDone && (
                        <>
                            Export Link Exchange Widgets to Slack <Icon name="forum" />
                        </>
                    )}
                </Button>
            </AutoRow>
        </div>
    )
}

export default ExportLinkExchangeWidget
