import { Row, Col } from '@advanza/grid'
import { Button, Divider, Icon, LinearLoader } from '@advanza/ui'
import SimpleOrderModal from 'components/ui/SimpleOrderModal'
import { useServiceBundler } from 'hooks/servicesHooks'
import React from 'react'
import style from 'components/services/serviceBundler.module.css'

const ServiceBundler = ({ serviceId, type }) => {
    const {
        isLoading,
        servicesList,
        activeIds,
        onSelectService,
        editMode,
        setEditMode,
        saveList,
        saveOrder,
        isSaving,
        error,
        maxServices,
    } = useServiceBundler(serviceId, type)

    if (editMode) {
        return (
            <div className={style.edit}>
                <Row>
                    {Object.keys(servicesList)
                        .map((id) => parseInt(id))
                        .map((serviceId, index) => (
                            <Col xs={4} key={index}>
                                <div
                                    className={style.service}
                                    onClick={() => onSelectService(serviceId)}>
                                    <div className={style.checkbox}>
                                        {activeIds.includes(serviceId) && (
                                            <Icon name="checked" primColor fontSize={18} />
                                        )}
                                    </div>
                                    {servicesList[serviceId]}
                                </div>
                            </Col>
                        ))}
                </Row>
                <Row end="xs">
                    {error && (
                        <Col x className={style.warning}>
                            <Divider s />
                            <Icon name="warning" red /> Choose max {maxServices} services for cross
                            selling
                        </Col>
                    )}
                    <Col x>
                        <Button onClick={saveList} disabled={isSaving}>
                            Save
                        </Button>
                    </Col>
                </Row>
            </div>
        )
    }

    return (
        <div className={style.view}>
            {isLoading && <LinearLoader fixed />}
            <Row>
                <Col x>
                    {activeIds.length > 0
                        ? activeIds.map((serviceId, index) => (
                              <span
                                  key={index}
                                  className={[
                                      style.serviceName,
                                      index < 3 ? style.primary : '',
                                  ].join(' ')}>
                                  {index > 0 && (index === activeIds.length - 1 ? ' and ' : ', ')}
                                  {servicesList[serviceId]}
                              </span>
                          ))
                        : type === 'Xsell'
                        ? 'No cross selling services selected'
                        : 'No related services selected'}
                </Col>
                <Col x>
                    <Button name="text" onClick={() => setEditMode(true)} disabled={isLoading}>
                        <Icon name="edit" className="colorCta" style={{ marginTop: '-4px' }} /> Edit
                    </Button>
                </Col>
                <Col x>
                    <SimpleOrderModal
                        orderedIds={activeIds}
                        disabled={isLoading}
                        text="The first 3 should be the most important cross selling services. Drag the services to adjust the order."
                        renderItem={(value, index) => (
                            <>
                                <span
                                    className={[
                                        style.serviceName,
                                        index < 3 ? style.primary : '',
                                    ].join(' ')}>
                                    {servicesList[value]}
                                </span>
                            </>
                        )}
                        onSave={saveOrder}
                    />
                </Col>
            </Row>
        </div>
    )
}

export default ServiceBundler
