import { InputField } from '@advanza/input'
import { Divider, Icon } from '@advanza/ui'
import Card from 'components/Card'
import AutoRow from 'components/misc/AutoRow'
import ExportLinkExchangeWidget from 'components/misc/ExportLinkExchangeWidget'
import ExportProvidersByLicence from 'components/misc/ExportProvidersByLicence'
import ExportProvidersByRegion from 'components/misc/ExportProvidersByRegion'
import ResendFailedEmails from 'components/misc/ResendFailedEmails'
import SendMonthlyMails from 'components/misc/SendMonthlyMails'
import InvalidateNextPageButton from 'components/services/InvalidateNextPageButton'
import Page from 'components/ui/Page'
import React from 'react'
import { Link } from 'react-router-dom'
import { ApiInstance } from '@advanza/api'
import Button from '@advanza/ui/Button'
import ExportPWAStatsButton from 'components/misc/ExportPWAStatsButton'
import ImportPlatformUrls from 'components/misc/ImportPlatformUrls'

const MiscPage = () => {
    const [invalidatePath, setInvalidatePath] = React.useState('')
    const [invalidateTag, setInvalidateTag] = React.useState('')
    return (
        <Page>
            <div className="m-auto max-w-screen-xl my-16 space-y-8">
                <div className="flex space-x-4 items-center">
                    <h1>Misc</h1>
                    <img
                        src="https://static.trustoo.nl/team/haring.png"
                        alt="haring"
                        className="animate-bounce h-16 hover:animate-spin"
                    />
                </div>
                <div className="flex flex-col gap-4">
                    <Card header="Invalidate Static Next Page or Tag">
                        <AutoRow>
                            <InputField
                                placeholder="path e.g.: /bedrijf-aanmelden/"
                                onChange={(e) => setInvalidatePath(e.target.value)}
                                value={invalidatePath}
                            />

                            <InputField
                                placeholder="Or Tags e.g.: services|all|schilder"
                                onChange={(e) => setInvalidateTag(e.target.value)}
                                before={<Icon name="humerus" />}
                                value={invalidateTag}
                            />
                            <InvalidateNextPageButton
                                paths={[invalidatePath]}
                                tag={invalidateTag}
                            />
                        </AutoRow>
                    </Card>
                    <Card header="Monthly mails premium">
                        <SendMonthlyMails />
                    </Card>
                    <Card useToggle header="Export">
                        <ExportProvidersByRegion />
                        <Divider m />
                        <ExportProvidersByLicence />
                        <Divider m />
                        <ExportLinkExchangeWidget />
                    </Card>
                    <Card useToggle header="Import">
                        <ImportPlatformUrls />
                        <Divider m />
                    </Card>
                    <Card
                        header="Resend failed emails"
                        topRight={
                            <small>
                                check issues on{' '}
                                <a
                                    style={{ textDecoration: 'underline' }}
                                    href="https://www.sanity-status.com/">
                                    sanity-status.com
                                </a>
                            </small>
                        }>
                        <ResendFailedEmails />
                    </Card>
                    <Card header="Auto-quotes reports">
                        <Link
                            to={`${ApiInstance.getAddress()}office/auto-quotes-reports/auto-quotes-sent`}
                            target={'_blank'}>
                            <Button>Auto-quotes sent</Button>
                        </Link>
                        <Divider s />
                        <Link
                            to={`${ApiInstance.getAddress()}office/auto-quotes-reports/activations`}
                            target={'_blank'}>
                            <Button>Activations</Button>
                        </Link>
                    </Card>
                    <Card header="Whatsapp to demand report">
                        <Link
                            to={`${ApiInstance.getAddress()}office/whatsapp-to-demand-report/get-report`}
                            target={'_blank'}>
                            <Button>Whatsapp to demand</Button>
                        </Link>
                    </Card>
                    <Card header="Filters added manually report">
                        <Link
                            to={`${ApiInstance.getAddress()}office/filters-added-manually-report/get-report`}
                            target={'_blank'}>
                            <Button>Filters added manually</Button>
                        </Link>
                    </Card>
                    <Card header="PWA Logs">
                        <ExportPWAStatsButton />
                    </Card>
                </div>
            </div>
        </Page>
    )
}

export default MiscPage
