import { call } from '@advanza/api'
import { Col, Row } from '@advanza/grid'
import { FileDropZone, Radio } from '@advanza/input'
import Modal from '@advanza/modal'
import { Button, Divider, Icon, PreIcon } from '@advanza/ui'
import FaqsList from 'components/faqs/FaqsList'
import Checkbox from 'components/input/Checkbox'
import KeywordsList from 'components/keywords/KeywordsList'
import SystemLogsList from 'components/logs/SystemLogsList'
import AutoRow from 'components/misc/AutoRow'
import AddOptionPageButton from 'components/services/AddOptionPageButton'
import CtasList from 'components/services/CtasList'
import GenerateTop10EmailWidgetsButton from 'components/services/GenerateTop10EmailWidgetsButton'
import InvalidateServiceCachesButton from 'components/services/InvalidateServiceCachesButton'
import OptionPageForm from 'components/services/OptionPageForm'
import ServiceActions from 'components/services/ServiceActions'
import ServiceBundler from 'components/services/ServiceBundler'
import ServiceConfigPrices from 'components/services/ServiceConfigPrices'
import TestimonialsList from 'components/testimonials/TestimonialsList'
import { getWebsiteHostnameByCountryCode } from 'misc/country'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import ServicesViewContainer from '../../containers/ServicesViewContainer'
import Card from '../Card'
import LinearLoader from '../LinearLoader'
import BackButton from '../ui/BackButton'
import EditImage from './EditImage'
import QuestionsList from './QuestionsList'
import Service from './Service'
import ServicesSbis from './ServicesSbis'

const APP = require('@advanza/advanza_generic').APP

class ServiceView extends React.Component {
    constructor(props) {
        super(props)
        this.saveBannerImage = this.saveBannerImage.bind(this)
        this.state = {
            scrape_result: false,
            scrape_error: false,
            import_result: false,
            import_error: false,
            loading: false,
            provider_option: 'ghost',
            subscription_option: 'active',
            keywords_to_scrape: [],
            open_modal: false,
        }
    }

    schedule(files, task) {
        let endpoint = 'office/services/schedule-' + task + '/' + this.props.serviceId
        let resultKey = task + '_result'
        let errorKey = task + '_error'

        this.setState({ loading: true })
        call(endpoint, {
            payload: {
                file: files[0],
                options: JSON.stringify({
                    provider_option: this.state.provider_option,
                    subscription_option: this.state.subscription_option,
                }),
            },
        }).then(
            (response) => {
                this.setState({
                    [resultKey]: JSON.stringify(response),
                    [errorKey]: false,
                    loading: false,
                })
            },
            (response) => {
                this.setState({
                    [resultKey]: JSON.stringify(response),
                    [errorKey]: true,
                    loading: false,
                })
            }
        )
    }

    onSetProviderOption = (value) => {
        this.setState({ provider_option: value })
    }

    onSetKeywordsToScrape = (value) => {
        this.setState({ keywords_to_scrape: value })
    }

    onSetModal = (value) => {
        this.setState({ open_modal: value })
    }

    onSetSubscriptionOption = (value) => {
        this.setState({ subscription_option: value })
    }

    onSetImportFile = (files) => {
        this.schedule(files, 'import')
    }

    onSetScrapeFile = (files) => {
        this.schedule(files, 'scrape')
    }

    componentDidMount() {
        const { fetchService, serviceId } = this.props
        fetchService(serviceId)
    }

    componentDidUpdate(prevProps) {
        const { fetchService, serviceId } = this.props
        if (serviceId !== prevProps.serviceId) {
            fetchService(serviceId)
        }
    }

    saveBannerImage() {
        const { saveServiceImage, serviceId } = this.props
        return (file, context) => saveServiceImage(serviceId, file, context)
    }

    warningText(text) {
        return (
            <PreIcon name="warning" red>
                <strong style={{ color: 'red', padding: '4px' }}>{text}</strong>
            </PreIcon>
        )
    }

    scheduleKeywordsOptions(filterableOptions, serviceId, includeSigned = 0) {
        let buttonTitle = includeSigned
            ? 'Schedule specific options (incl. unfilled claimed/signed)'
            : 'Schedule specific options'
        let modalName = includeSigned
            ? 'selected_keywords_options_two_signed'
            : 'selected_keywords_options_two'
        return (
            <Fragment>
                <Button onClick={() => this.onSetModal(modalName)}>
                    {buttonTitle} <Icon name="forum" />{' '}
                    {includeSigned ? <Icon name="warning" /> : ''}
                </Button>
                <Modal
                    close={() => this.onSetModal(false)}
                    maxWidth={600}
                    open={this.state.open_modal === modalName}>
                    <div>
                        <h3>Only these selected options will be activated via the keywords</h3>
                        <Divider />
                        <div>
                            <Checkbox
                                options={filterableOptions.map((a) => {
                                    return { value: a.option_id, name: a.value }
                                })}
                                onChangeValue={this.onSetKeywordsToScrape}
                                value={this.state.keywords_to_scrape || []}
                            />
                        </div>
                        <Divider />
                        <Row>
                            {includeSigned ? (
                                <Fragment>
                                    {this.warningText(
                                        'This will affect signed and claimed providers.'
                                    )}
                                    <Divider />
                                </Fragment>
                            ) : (
                                ''
                            )}
                            {this.state.keywords_to_scrape.length ? (
                                <Col x>
                                    {this.buttonScheduleKeywords(buttonTitle, [
                                        serviceId,
                                        includeSigned,
                                        this.state.keywords_to_scrape,
                                    ])}
                                </Col>
                            ) : (
                                'Select options'
                            )}
                        </Row>
                        <Divider />
                    </div>
                </Modal>
            </Fragment>
        )
    }

    confirmWarningButton(
        buttonText,
        buttonElement,
        warningText = 'This will affect signed and claimed providers.',
        modalTitle = 'Confirm?'
    ) {
        const modalName = buttonText.replace(/\W/g, '_')
        return (
            <Fragment>
                <Button onClick={() => this.onSetModal(modalName)}>
                    {buttonText} <Icon name="forum" /> <Icon name="warning" />
                </Button>
                <Modal
                    close={() => this.onSetModal(false)}
                    maxWidth={600}
                    open={this.state.open_modal === modalName}>
                    <div>
                        <h3>{modalTitle}</h3>
                        <Divider />
                        {this.warningText(warningText)}
                        <Divider />
                        <Row>{buttonElement}</Row>
                        <Divider />
                    </div>
                </Modal>
            </Fragment>
        )
    }

    buttonScheduleKeywords(name, params, action = 'schedule-keywords-task') {
        return (
            <a
                target="_blank"
                href={`/api/office/providers/${action}/${params.join('/')}`}
                rel="noreferrer">
                <Button>{name}</Button>
            </a>
        )
    }

    render() {
        const { serviceId, service, optionPages, onFilesRejected, serviceEntities } = this.props
        let filterableOptions =
            serviceEntities && serviceEntities.options
                ? Object.keys(serviceEntities.options)
                      .map((option_id) => {
                          let question_id = serviceEntities.options[option_id].question_id
                          let question = serviceEntities.questions[question_id]
                          return question.filterable && question.service_id === service.service_id
                              ? serviceEntities.options[option_id]
                              : false
                      })
                      .filter(Boolean)
                : []

        if (!service) {
            return <LinearLoader fixed />
        }
        const identifier =
            service.country_code !== 'NL' ? service.url + '-' + service.country_code : service.url

        return (
            <div>
                <Divider l />
                <AutoRow>
                    <BackButton fallBack="/settings/services" />
                    <h1>{service.name} </h1>
                    {service.mainServiceOptionName && (
                        <Link to={`/settings/services/service/${service.mainServiceId}`}>
                            <h3>
                                {' '}
                                <Icon name="sync_alt" /> {service.mainServiceOptionName}
                            </h3>
                        </Link>
                    )}
                    {service.subServices && (
                        <div>
                            {service.subServices.split(',').map((items) => {
                                const [service, serviceId, optionName] = items.split('|')
                                return (
                                    <Link to={`/settings/services/service/${serviceId}`}>
                                        <AutoRow>
                                            <PreIcon
                                                tagName="span"
                                                icon="radio_button_checked"
                                                fontSize={16}>
                                                {optionName}
                                            </PreIcon>
                                            <PreIcon tagName="span" icon="sync_alt" fontSize={16}>
                                                <b>{service}</b>
                                            </PreIcon>
                                        </AutoRow>
                                    </Link>
                                )
                            })}
                        </div>
                    )}{' '}
                    <Link to={`/settings/services/service/${serviceId}/questions/`}>
                        <Button name="text">
                            <PreIcon primColor icon="dynamic_form">
                                Questionnaires
                            </PreIcon>
                        </Button>
                    </Link>
                    <InvalidateServiceCachesButton serviceId={service.service_id} />
                    <a
                        target="_blank"
                        href={`//${getWebsiteHostnameByCountryCode(
                            service.country_code
                        )}/nederland/${service.url}/`}>
                        <Button name="text">
                            <Icon name="open" />
                            Top10 page
                        </Button>
                    </a>
                    <div></div>
                    <Link to={`/settings/seo/services-places-contents/?service=${serviceId}`}>
                        <Button name="link">
                            <PreIcon icon="open_in_new_tab" primColor>
                                SEO content
                            </PreIcon>
                        </Button>
                    </Link>
                </AutoRow>
                <Divider />
                <Row>
                    <Col xs={5}>
                        <div className="grid grid-flow-col items-center">
                            <EditImage
                                src={`${APP.S3_SERVICES()}${service.country_code.toLowerCase()}/img/${
                                    service.url
                                }.webp`}
                                context="top10"
                                height={200}
                                bgPositionSize="center center/contain"
                                save={this.saveBannerImage()}
                                mime="image/webp"
                                onFilesRejected={onFilesRejected}>
                                <div className="text-lg font-bold">Webp image</div>
                                <div style={{ fontSize: '0.85em' }}>type: .webp</div>
                            </EditImage>
                            <EditImage
                                src={`${APP.S3_SERVICES()}${service.country_code.toLowerCase()}/img/${
                                    service.url
                                }_sm.webp`}
                                context="top10_sm"
                                height={200}
                                bgPositionSize="center center/contain"
                                save={this.saveBannerImage()}
                                mime="image/webp"
                                onFilesRejected={onFilesRejected}>
                                <div className="text-lg font-bold">Webp image SM</div>
                                <div style={{ fontSize: '0.85em' }}>(without text) type: .webp</div>
                            </EditImage>
                            <EditImage
                                src={`${APP.S3_SERVICES()}${service.country_code.toLowerCase()}/img/${
                                    service.url
                                }_xs.webp`}
                                context="top10_xs"
                                height={200}
                                bgPositionSize="center center/contain"
                                save={this.saveBannerImage()}
                                mime="image/webp"
                                onFilesRejected={onFilesRejected}>
                                <div className="text-lg font-bold">Webp image XS</div>
                                <div style={{ fontSize: '0.85em' }}>
                                    (without lines/text) type: .webp
                                </div>
                            </EditImage>
                            <EditImage
                                src={`${APP.S3_SERVICES()}${service.country_code.toLowerCase()}/symbol/${
                                    service.url
                                }.svg`}
                                context="symbol"
                                height={200}
                                bgPositionSize="center center/contain"
                                save={this.saveBannerImage()}
                                mime="image/svg+xml"
                                onFilesRejected={onFilesRejected}>
                                <div className="text-lg font-bold">Symbol</div>
                                <div style={{ fontSize: '0.85em' }}>(redesign icon) type .svg</div>
                            </EditImage>
                            <EditImage
                                src={`${APP.S3_SERVICES()}img/icon/${identifier}/icon.svg`}
                                context="icon"
                                height={200}
                                bgPositionSize="center center/contain"
                                save={this.saveBannerImage()}
                                mime="image/svg+xml"
                                onFilesRejected={onFilesRejected}>
                                <div className="text-lg font-bold">Icon</div>
                                <div style={{ fontSize: '0.85em' }}>(.svg)</div>
                            </EditImage>
                            <div style={{ maxWidth: '150px' }}>
                                <EditImage
                                    src={`${APP.S3_SERVICES()}img/xsell/${identifier}.png`}
                                    context="xsell"
                                    height={100}
                                    bgPositionSize="center center/contain"
                                    save={this.saveBannerImage()}
                                    mime="image/png"
                                    onFilesRejected={onFilesRejected}>
                                    <div className="text-lg font-bold">xsell email</div>
                                    <div style={{ fontSize: '0.85em' }}>
                                        height: 100px
                                        <br />
                                        type: .png
                                    </div>
                                </EditImage>
                            </div>
                        </div>
                        <Divider m />
                        <Card header="AC" icon="assignment">
                            <Divider m />
                            <AutoRow>
                                <a
                                    target="_blank"
                                    href={
                                        '/api/office/providers/download-active-campaign-sheet/' +
                                        serviceId +
                                        '?ac=1'
                                    }>
                                    <Button name="borderedPoppins">Sheet for import</Button>
                                </a>
                                <GenerateTop10EmailWidgetsButton serviceId={serviceId} />
                            </AutoRow>
                        </Card>
                        <Divider m />
                        <Card header="SBIs" icon="category" useToggle>
                            <Divider m />
                            <ServicesSbis serviceId={serviceId} />
                        </Card>
                    </Col>
                    <Col xs>
                        <Card header="Settings" icon="settings" useToggle>
                            <Divider m />
                            <Service service={service} entityId={serviceId} />
                        </Card>
                        <Divider m />
                        <Card header="Testimonial" icon="thumb_up" useToggle>
                            <Divider m />
                            <TestimonialsList
                                filterId={`f_${serviceId}`}
                                options={{ service_id: serviceId }}
                            />
                        </Card>
                        <Divider m />
                        <Card header="FAQs" icon="contact_support" useToggle>
                            <Divider m />
                            <FaqsList
                                filterId={`f_${serviceId}`}
                                service={service}
                                options={{ service_id: serviceId }}
                            />
                        </Card>
                        <Divider m />
                        <Card header="Xsell (Demand-facing)" icon="addchart">
                            <Divider m />
                            <ServiceBundler serviceId={serviceId} type={'Xsell'} />
                        </Card>
                        <Divider m />
                        <Card header="Related Services (Supply-facing)" icon="addchart">
                            <Divider m />
                            <ServiceBundler serviceId={serviceId} type={'Related'} />
                        </Card>
                        {optionPages.map((optionPage, i) => {
                            return (
                                <Fragment key={i}>
                                    <Divider m />
                                    <Card
                                        useToggle
                                        icon={!optionPage.keyword_singular ? 'fiber_new' : 'filter'}
                                        header={`Optionpage: ${
                                            optionPage.keyword_singular || 'NEW'
                                        }`}>
                                        <Divider m />
                                        <OptionPageForm
                                            serviceId={serviceId}
                                            id={optionPage.option_page_id}
                                        />
                                        <Divider m />
                                        <b>Faqs</b>
                                        <Divider />
                                        <FaqsList
                                            filterId={`f_${serviceId}_${optionPage.option_id}`}
                                            options={{
                                                service_id: serviceId,
                                                option_id: optionPage.option_id,
                                            }}
                                        />
                                    </Card>
                                </Fragment>
                            )
                        })}
                        <Divider />
                        <AddOptionPageButton serviceId={serviceId} />
                    </Col>
                </Row>
                <Divider m />
                <style>{`
                    .cta-i {
                        transition: transform 0.2s ease 0s;
                    }
                    
                    .ctas.open .cta-i {
                        transform: scaleX(-1);
                    }
                `}</style>
                <Card
                    className="ctas"
                    classNameIcon="cta-i"
                    header="CTAs"
                    icon="sports_martial_arts"
                    useToggle>
                    <Divider m />
                    <CtasList serviceId={parseInt(serviceId, 10)} />
                </Card>
                <Divider m />
                <Card header="Prices" icon="euro_symbol" useToggle>
                    <Divider m />
                    <ServiceConfigPrices serviceId={serviceId} />
                </Card>
                <Divider m />
                <Card header="Questionnaires" icon="question_answer">
                    <Divider m />
                    <QuestionsList serviceId={serviceId} />
                </Card>
                <Divider xl />
                <Card header="Keywords" useToggle icon="🧖🏻">
                    <Divider m />
                    <KeywordsList
                        filterId={`f_${serviceId}`}
                        options={{ service_id: serviceId }}
                        serviceId={serviceId}
                    />
                    <Divider m />
                    <Card header="Tasks" useToggle icon="assignment">
                        <Divider m />
                        {this.buttonScheduleKeywords('Schedule keywords', [serviceId])}{' '}
                        {this.confirmWarningButton(
                            'Schedule keywords (incl. unfilled claimed/signed)',
                            this.buttonScheduleKeywords(
                                'Schedule keywords (incl. unfilled claimed/signed)',
                                [serviceId, 1]
                            )
                        )}
                        <Divider m />
                        {this.scheduleKeywordsOptions(filterableOptions, serviceId, 0)}{' '}
                        {this.scheduleKeywordsOptions(filterableOptions, serviceId, 1)}
                        <Divider m />
                        {this.buttonScheduleKeywords(
                            'Set defaults',
                            [serviceId],
                            'set-default-keywords'
                        )}{' '}
                        {this.confirmWarningButton(
                            'Set defaults (include signed)',
                            this.buttonScheduleKeywords(
                                'Set defaults (include signed)',
                                [serviceId, 1],
                                'set-default-keywords'
                            )
                        )}
                    </Card>
                </Card>
                <Divider m />
                <Card header={'Actions'} useToggle icon={'assignment'}>
                    <Divider m />
                    <ServiceActions serviceId={serviceId} />
                    <Divider m />
                    <Card header={'scrape'} useToggle icon={'assignment'}>
                        <div>
                            {
                                "The file must be csv and contain the following in the filename: 'url_scrape'"
                            }
                        </div>
                        <FileDropZone
                            onFilesAccepted={this.onSetScrapeFile}
                            onFilesRejected={onFilesRejected}>
                            <Button>scrape file</Button>
                        </FileDropZone>
                        <Divider m />
                        <div style={{ color: this.state.scrape_error ? 'red' : 'inherit' }}>
                            {this.state.scrape_result}
                        </div>
                        {this.state.loading && <LinearLoader absolute />}
                    </Card>
                    <Divider m />
                    <Card header={'import'} useToggle icon={'assignment'}>
                        <div>
                            {
                                "The file must be xlsx and contain the following in the filename: 'import', the service_id, the service_url, and the service country_code"
                            }
                        </div>
                        <Divider m />
                        Provider status:{' '}
                        <Radio
                            options={[
                                { value: 'ghost', name: 'GHOST' },
                                { value: 'new_import', name: 'NEW_IMPORT' },
                            ]}
                            onChange={(e) => {
                                this.onSetProviderOption(e.target.value)
                            }}
                            value={this.state.provider_option}
                        />
                        <Divider m />
                        Subscription status:{' '}
                        <Radio
                            options={[
                                { value: 'active', name: 'ACTIVE' },
                                { value: 'paused', name: 'PAUSED' },
                            ]}
                            onChange={(e) => {
                                this.onSetSubscriptionOption(e.target.value)
                            }}
                            value={this.state.subscription_option}
                        />
                        <Divider m />
                        <FileDropZone
                            mime="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" // .xlsx
                            multiple={false}
                            maxFiles={1}
                            onFilesAccepted={this.onSetImportFile}
                            onFilesRejected={onFilesRejected}>
                            <Button>import file</Button>
                        </FileDropZone>
                        <Divider m />
                        <div style={{ color: this.state.import_error ? 'red' : 'inherit' }}>
                            {this.state.import_result}
                        </div>
                        {this.state.loading && <LinearLoader absolute />}
                    </Card>
                </Card>
                <Divider m />
                <Divider xl />
                <Card header="Logs" icon="🪵">
                    <Divider m />
                    <SystemLogsList
                        filterId={`systemlogs_${serviceId}`}
                        options={{
                            relation: 'services',
                            serviceIds: [serviceId],
                        }}
                    />
                </Card>
                <Divider xxxl />
                <Divider xxxl />
            </div>
        )
    }
}

ServiceView.propTypes = {
    serviceId: PropTypes.string,

    service: PropTypes.object,
    serviceEntities: PropTypes.object,
    fetchService: PropTypes.func,
}

export default ServicesViewContainer(ServiceView)
