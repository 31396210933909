import { Button, Divider, MaxWidth } from '@advanza/ui'
import AutoRow from 'components/misc/AutoRow'
import MissingPostalCodeIdWarning from 'components/providers/MissingPostalCodeIdWarning'
import ProfileUrlLink from 'components/providers/ProfileUrlLink'
import ProviderViewLeads from 'components/providers/ProviderViewLeads'
import ProviderViewOverview from 'components/providers/ProviderViewOverview'
import ProviderViewProfile from 'components/providers/ProviderViewProfile'
import ProviderViewReviews from 'components/providers/ProviderViewReviews'
import StepsRow from 'components/ui/StepsRow'
import { historyProfileTabs } from 'hooks/miscHooks'
import { getFlag } from 'misc/flags'
import { isProfileBuilder } from 'misc/user'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import ProvidersViewContainer from '../../containers/ProvidersViewContainer'
import LinearLoader from '../LinearLoader'
import BackButton from '../ui/BackButton'

class ProviderView extends React.Component {
    constructor(props) {
        super(props)
        this.fetchProvider = this.fetchProvider.bind(this)
    }

    componentDidMount() {
        this.fetchProvider()
    }

    fetchProvider() {
        const { fetchProvider, providerId } = this.props
        const { setTab } = historyProfileTabs(this.props.dispatch)
        fetchProvider(providerId).then((provider) => {
            setTab(provider, window.location.pathname)
        })
        window.scrollTo(0, 0)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.providerId !== prevProps.providerId) {
            this.fetchProvider()
        }
        if (this.props.tab !== prevProps.tab && this.props.provider) {
            const { setTab } = historyProfileTabs(this.props.dispatch)
            setTab(this.props.provider, window.location.pathname)
        }
    }

    copyLinkToClipboard(ref) {
        if (ref) {
            ref.select()
            setTimeout(() => document.execCommand('copy'), 100)
        }
    }

    render() {
        const {
            provider,
            isFetching,
            tab = 'company',
            providerEntities,
            providerId,
            generateValidateProfileLink,
            generateDashboardLink,
            services,
        } = this.props

        // check that we have the provider with the extra fields that only findOfficeItem provides
        if (!provider?.link_exchange_widgets) {
            return <LinearLoader fixed />
        }

        const service = (services && services[provider.serviceId]) || {}
        const { users } = providerEntities
        const user = users[provider.user]
        return (
            <div>
                <Helmet>
                    <title>Office - {provider.business_name}</title>
                </Helmet>
                {isFetching && <LinearLoader fixed />}
                {provider && <MissingPostalCodeIdWarning provider={provider} />}
                <MaxWidth maxWidth={1200}>
                    <AutoRow noWrap between>
                        <AutoRow noWrap>
                            <BackButton fallBack="/service/providers" />
                            <h4 style={{ verticalAlign: 'middle' }}>
                                {provider.business_name} {getFlag(provider.country_code)}
                            </h4>
                        </AutoRow>
                        <AutoRow noWrap>
                            {!isFetching && <ProfileUrlLink provider={provider} />}
                            {provider._validateProfileLink ? (
                                <input
                                    style={{ textOverflow: 'ellipsis' }}
                                    ref={this.copyLinkToClipboard}
                                    value={provider._validateProfileLink}
                                />
                            ) : (
                                <Button
                                    name="textReadMore"
                                    onClick={(e) => generateValidateProfileLink(providerId)}>
                                    Validate profile
                                </Button>
                            )}
                            {provider._dashboardLink ? (
                                <input
                                    style={{ textOverflow: 'ellipsis' }}
                                    ref={this.copyLinkToClipboard}
                                    value={provider._dashboardLink}
                                />
                            ) : (
                                <Button
                                    name="textReadMore"
                                    onClick={(e) => generateDashboardLink(providerId)}>
                                    Create dashboardlink
                                </Button>
                            )}
                        </AutoRow>
                    </AutoRow>
                    <Divider />
                    <StepsRow
                        selected={tab}
                        items={[
                            ...(isProfileBuilder()
                                ? []
                                : [
                                      {
                                          name: 'Company',
                                          id: 'company',
                                          url: `/service/providers/provider/${providerId}/company`,
                                      },
                                      {
                                          name: 'Leads',
                                          id: 'leads',
                                          url: `/service/providers/provider/${providerId}/leads`,
                                      },
                                  ]),
                            {
                                name: 'Profile',
                                id: 'profile',
                                url: `/service/providers/provider/${providerId}/profile`,
                            },
                            {
                                name: 'Reviews & score',
                                id: 'reviews',
                                url: `/service/providers/provider/${providerId}/reviews`,
                            },
                        ]}
                    />
                    <Divider />

                    {tab === 'company' && <ProviderViewOverview providerId={providerId} />}
                    {tab === 'reviews' && <ProviderViewReviews providerId={providerId} />}
                    {tab === 'leads' && <ProviderViewLeads providerId={providerId} />}
                    {tab === 'profile' && (
                        <ProviderViewProfile
                            providerId={providerId}
                            user={user}
                            provider={provider}
                        />
                    )}
                </MaxWidth>
            </div>
        )
    }
}

export default ProvidersViewContainer(ProviderView)
