import { call } from '@advanza/api'
import Modal from '@advanza/modal'
import { LoadingDots, Divider, Icon, Toggle } from '@advanza/ui'
import { fetchProvider } from 'actions/providers'
import LogsList from 'components/logs/LogsList'
import SafeButton from 'components/ui/SafeButton'
import { formatRelative } from 'date'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import style from './LinkExchangeWidget.module.css'

function useLinkExchangeWidget(providerId) {
    const { link_exchange_widgets } = useSelector(
        (state) => state.providers.entities.providers[providerId]
    )
    const linkScoreWidget = link_exchange_widgets.find(
        (link_exchange_widget) =>
            link_exchange_widget.widget_type === 'score' && link_exchange_widget.widget_first_seen
    )
    const linkReviewWidget = link_exchange_widgets.find(
        (link_exchange_widget) =>
            link_exchange_widget.widget_type === 'review' && link_exchange_widget.widget_first_seen
    )
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)
    const [response, setResponse] = useState(null)

    const activate = (widgetType) => {
        setIsLoading(true)
        return call('office/providers/activate-link-exchange-widget/' + providerId+'/'+widgetType, {
            method: 'post',
        })
            .then(() => {
                dispatch(fetchProvider(providerId, true))
                setResponse('Link exchange is activated')
            }, setResponse)
            .finally(() => {
                setIsLoading(false)
            })
    }
    const deActivate = (widgetType) => {
        setIsLoading(true)
        return call('office/providers/de-activate-link-exchange-widget/' + providerId+'/'+widgetType, {
            method: 'post',
        })
            .then(() => {
                dispatch(fetchProvider(providerId, true))
                dispatch({ type: 'INVALIDATE_LOGS' })
                setResponse('Link exchange is de-activated')
            }, setResponse)
            .finally(() => {
                setIsLoading(false)
            })
    }

    return {
        isActiveScore: linkScoreWidget && linkScoreWidget.active,
        isActiveReview: linkReviewWidget && linkReviewWidget.active,
        linkExchangeScoreWidget: linkScoreWidget,
        linkExchangeReviewWidget: linkReviewWidget,
        activate,
        deActivate,
        response,
        isLoading,
        closeModal: () => setResponse(null),
    }
}

const LinkExchangeWidget = ({ providerId }) => {
    const {
        linkExchangeScoreWidget,
        linkExchangeReviewWidget,
        isActiveScore,
        isActiveReview,
        activate,
        deActivate,
        isLoading,
        response,
        closeModal,
    } = useLinkExchangeWidget(providerId)
    return (
        <div className={style.root}>
            <div className={style.row} style={{ display: 'grid' }}>
                {isActiveScore && linkExchangeScoreWidget && (
                    <div className={style.green}>
                        <b>Website link enabled for Score Widget</b>
                    </div>
                )}
                {linkExchangeScoreWidget && (<SafeButton
                    textButton
                    action={()=> {isActiveScore ? deActivate('score') : activate('score')}}
                    disabled={isLoading}
                    buttonText={
                        isLoading ? (
                            <LoadingDots />
                        ) : isActiveScore ? (
                            'De-activate link exchange for Score Widget'
                        ) : (
                            'Score Widget: Activate link exchange (show website link)'
                        )
                    }
                />)}
                <div>
                {isActiveReview &&  linkExchangeReviewWidget &&(
                    <div className={style.green}>
                        <b>Website link enabled for Review Widget</b>
                    </div>
                )}
                {linkExchangeReviewWidget && (
                <SafeButton
                    textButton
                    action={()=> {isActiveReview ? deActivate('review') : activate('review')}}
                    disabled={isLoading}
                    buttonText={
                        isLoading ? (
                            <LoadingDots />
                        ) : isActiveReview ? (
                            'De-activate link exchange for Review Widget'
                        ) : (
                            'Review Widget: Activate link exchange (show website link)'
                        )
                    }
                />)}
                </div>
            </div>
            {linkExchangeScoreWidget && (
                <div className={style.stats}>
                    <b>Score Widget last seen: </b>{' '}
                    {formatRelative(linkExchangeScoreWidget.widget_last_seen)}
                    <Divider />
                    <small>
                        Score Widget first seen:{' '}
                        {formatRelative(linkExchangeScoreWidget.widget_first_seen)} (counter:{' '}
                        {linkExchangeScoreWidget.visit_count})
                    </small>{' '}
                    <Icon
                        name="explore"
                        fontSize={16}
                        title={linkExchangeScoreWidget.widget_location}
                    />
                </div>
            )}
            {linkExchangeReviewWidget && (
                <div className={style.stats}>
                    <b>Review Widget last seen: </b>{' '}
                    {formatRelative(linkExchangeReviewWidget.widget_last_seen)}
                    <Divider />
                    <small>
                        Review Widget first seen:{' '}
                        {formatRelative(linkExchangeReviewWidget.widget_first_seen)} (counter:{' '}
                        {linkExchangeReviewWidget.visit_count})
                    </small>{' '}
                    <Icon
                        name="explore"
                        fontSize={16}
                        title={linkExchangeReviewWidget.widget_location}
                    />
                </div>
            )}
            {!linkExchangeScoreWidget && (<div><small>Score Widget not installed</small></div>)}
            {!linkExchangeReviewWidget && (
                <div><small>Review Widget not installed</small></div>
            )}
            <Toggle title="logs" showIcon dontMountChildrenUntilOpen>
                <div className={style.logs}>
                    <LogsList
                        filterId={`pro_${providerId}_link_exchange`}
                        hideExport
                        options={{
                            providerIds: [providerId],
                            logTypes: ['link_exchange'],
                            pageSize: 10,
                        }}
                    />
                </div>
            </Toggle>
            <Modal open={response} close={closeModal}>
                {response && <pre>{JSON.stringify(response, null, 2)}</pre>}
            </Modal>
        </div>
    )
}

export default LinkExchangeWidget
