import { call } from '@advanza/api'
import { useCallback, useEffect, useState } from 'react'

export function useServiceBundler(serviceId, type) {
    const [servicesList, setServicesList] = useState({})
    const [activeIds, setActiveIds] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [error, setError] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const [maxServices] = useState(type === 'Xsell' ? 6 : 99)

    const getList = useCallback(() => {
        setIsLoading(true)
        call(`office/sub-categories/get-bundled-services-list/${serviceId}/${type}`).then(
            ({ list, bundled }) => {
                setServicesList(list)
                setActiveIds(
                    bundled.services ? bundled.services.map((service) => service.service_id) : []
                )
                setIsLoading(false)
            }
        )
    }, [serviceId, type])

    const onSelectService = (serviceId) => {
        error && setError(false)
        if (activeIds.includes(serviceId)) {
            setActiveIds(activeIds.filter((id) => id !== serviceId))
        } else {
            if (activeIds.length === maxServices) {
                setError(true)
            } else {
                setActiveIds([...activeIds, serviceId])
            }
        }
    }

    const saveList = () => {
        setIsSaving(true)
        call(`office/sub-categories/save-bundled-services-list/${serviceId}/${type}`, {
            json: { activeIds },
        }).then(() => {
            setIsSaving(false)
            setEditMode(false)
        })
    }

    const saveOrder = (newOrder) => {
        setIsSaving(true)
        call(`office/sub-categories/save-bundled-service-order/${serviceId}/${type}`, {
            json: { newOrder },
        }).then(() => {
            setActiveIds(newOrder)
            setIsSaving(false)
        })
    }

    useEffect(() => {
        getList()
    }, [getList])

    return {
        isLoading,
        servicesList,
        activeIds,
        onSelectService,
        editMode,
        setEditMode,
        saveList,
        saveOrder,
        isSaving,
        error,
        maxServices,
    }
}
