import { Icon, PreIcon } from '@advanza/ui'
import { useEffect, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import style from './fixedWarningPopup.module.css'

const FixedWarningPopup = ({
    show,
    fixed,
    children,
    red,
    orange,
    green,
    fromTop,
    className = '',
    timeout = 5000,
    icon,
    closeBtn = false,
    onExited = () => {},
}) => {
    let timeoutId = false

    // Set initial value on mount to ensure the animation always works.
    const [enter, setEnter] = useState(false)
    useEffect(() => {
        setEnter(show)
    }, [])

    const onDismiss = () => {
        setEnter(false)
        clearTimeout(timeoutId)
    }

    useEffect(() => {
        if (timeout) {
            timeoutId = setTimeout(() => {
                onDismiss()
            }, timeout)
        }
        setEnter(show)
        return () => clearTimeout(timeoutId)
    }, [show])

    const classNames = [
        style.container,
        className,
        fixed ? style.fixed : '',
        red ? style.red : '',
        orange ? style.orange : '',
        green ? style.green : '',
    ].join(' ')
    return (
        <div style={style} className={classNames}>
            <CSSTransition
                timeout={{
                    appear: 300,
                    enter: 300,
                    exit: 500,
                }}
                unmountOnExit
                in={Boolean(enter)}
                classNames={{
                    ...style,
                    enter: fromTop ? style.fromTopEnter : style.enter,
                }}
                onExited={onExited}>
                <div onClick={onDismiss} className={style.root}>
                    <div className={style.body}>
                        {icon !== false ? (
                            <PreIcon overflowFix icon={icon || 'emergency_home'}>
                                {children}
                            </PreIcon>
                        ) : (
                            children
                        )}
                    </div>
                    {closeBtn && <Icon className={style.closeBtn} name="close" fontSize={21} />}
                </div>
            </CSSTransition>
        </div>
    )
}
export default FixedWarningPopup
